import React, { useState, useEffect } from "react";
import { Row, Col, Card, Alert } from "react-bootstrap";
import Loading from "components/loading/Loading";
import HtmlHead from "components/html-head/HtmlHead";
import useCustomLayout from "hooks/useCustomLayout";
import { useSelector } from "react-redux";
import { MENU_PLACEMENT, LAYOUT } from "constants.js";

const VerticalPage = () => {
  const title = "Vizyon Aksaray Yönetim Paneli";
  const description = "";
  useCustomLayout({ placement: MENU_PLACEMENT.Vertical, layout: LAYOUT.Fluid });
  const [loading, setLoading] = useState(true);
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          <Row>
            {/* Sales & Stocks Charts Start */}

            <Col xl="12" md="12">
              <Card className="mb-2" id="introFirst">
                <Card.Body>
                  <h4>
                    Sayın; {currentUser.adsoyad} {currentUser.kurumadi}{" "}
                  </h4>
                  <hr className="mt-0 mb-4" />
                  <Row>
                    <Col md="12">
                      <Alert variant="danger">
                        <h1 className="alert-heading">Dikkat!</h1>
                        <hr />
                        <h3>
                          Kurumlar için Brifingler ekranı açılmıştır.
                          Kurumlarınıza ait brifingleri .pdf formatında
                          yüklemeniz gerekmektedir.
                        </h3>
                        <hr />
                        <h3>
                          İlçe ve Kurum Raporları artık ayda bir defa olarak
                          yüklenecektir.{" "}
                        </h3>
                      </Alert>
                    </Col>
                  </Row>
                  <Row className="g-0">
                    <p>
                      <b
                        className="text-primary"
                        style={{ fontSize: "1.0rem" }}
                      >
                        Sayın VizyonAksaray yetkilisi sistemde bir kaç
                        değişiklik yapılmıştır. Bu değişiklikler; <br />
                      </b>
                    </p>
                    <br />
                    <p>
                      1- Artık yatırımlarınızı harita üzerinde görebilir ve
                      düzenleyebilirsiniz.
                    </p>{" "}
                    <p>
                      2- Kurum faaliyetlerinizi ekleyebilir ve
                      düzenleyebilirsiniz.
                    </p>{" "}
                    <p>
                      3- Kurumlarınıza ait Veri Başlıklarını ekleyebilir ve
                      silebilirsiniz{" "}
                    </p>{" "}
                    <p>
                      4- Kurum verilerinizi kendiniz düzenleyebilirsiniz{""}{" "}
                    </p>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default VerticalPage;
