import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import Loading from "components/loading/Loading";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { resimyolu } from "../../../config";
import Servis from "../../../services/servis";

const YatirimResimleri = ({ resimler, yatirimId }) => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [resimlerDizisi, setResimlerDizisi] = useState([]);

  const openLightbox = (index) => {
    setSelectedImage(index);
    setIsOpen(true);
  };

  useEffect(() => {
    if (typeof resimler === "string") {
      const resimlerArray = resimler.split(";");
      setResimlerDizisi(resimlerArray);
    } else if (Array.isArray(resimler)) {
      setResimlerDizisi(resimler);
    } else {
      setResimlerDizisi([]);
    }
    setLoading(false);
  }, [resimler]);

  const resimSil = async (resimAdi) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu resmi silmek istediğinizden emin misiniz?" + resimAdi,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "İptal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await Servis.deleteYap(
          `yatirimlar/resim/${yatirimId}/${resimAdi}`
        );
        if (resp.data.status) {
          Swal.fire("Silindi!", "Resim başarıyla silindi.", "success");
          setResimlerDizisi((prevResimler) =>
            prevResimler.filter((resim) => resim !== resimAdi)
          );
        } else {
          Swal.fire("Hata!", "Resim silinirken bir hata oluştu.", "error");
        }
      }
    });
  };

  const resimYukle = async (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("resim", file);
    });

    formData.append("id", yatirimId); // Yatırım ID'sini formData'ya ekle

    try {
      const resp = await Servis.fileUpload(formData);
      if (resp.data.status) {
        Swal.fire("Yüklendi!", "Resim başarıyla yüklendi.", "success");
        setResimlerDizisi((prevResimler) => [
          ...prevResimler,
          ...resp.data.filePaths,
        ]);
      } else {
        Swal.fire("Hata!", "Resim yüklenirken bir hata oluştu.", "error");
      }
    } catch (error) {
      Swal.fire("Hata!", "Resim yüklenirken bir hata oluştu.", "error");
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Card className="mt-5">
            <Card.Body>
              <h2>
                <b>Yatırım Resimleri</b>
              </h2>
              <hr className="mt-1 mb-4" />
              <Row className="row-cols-1 row-cols-xl-5 g-2">
                {resimlerDizisi.map((resim, index) => (
                  <Col key={index}>
                    <div className="card hover-img-scale-up position-relative">
                      <img
                        className="card-img sh-15 sh-sm-25 scale cursor-pointer"
                        alt={resim}
                        onClick={() => openLightbox(index)}
                        src={resimyolu + resim}
                      />
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => resimSil(resim)}
                      >
                        Sil
                      </Button>
                    </div>
                  </Col>
                ))}
              </Row>
              <Col mb={8} xl={8} className="mt-5">
                <Row>
                  <Col mb={6} xl={6} className="mt-5">
                    <Form.Group className="mb-3">
                      <Form.Label>Yeni Resim Ekle</Form.Label>
                      <Form.Control
                        type="file"
                        multiple
                        onChange={resimYukle}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              {isOpen && selectedImage !== null && (
                <Lightbox
                  mainSrc={resimyolu + resimlerDizisi[selectedImage]}
                  onCloseRequest={() => setIsOpen(false)}
                />
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default YatirimResimleri;
