import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loading from "components/loading/Loading";
import HtmlHead from "components/html-head/HtmlHead";
import Servis from "../../services/servis";
import ListeYukleModal from "./ListeYukleModal";
import { useSelector } from "react-redux";

const BrifingListesi = () => {
  const [brifingler, setBrifingler] = useState([]);
  const [loading, setLoading] = useState(true);
  const title = "Brifing Listesi";
  const description = "";
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.auth);

  const getListe = async () => {
    try {
      const sonuc = await Servis.getYap("brifinglistesi");
      if (sonuc && sonuc.status) {
        if (currentUser.yetki === "Sistem Yöneticisi") {
          setBrifingler(sonuc.data);
        } else {
          setBrifingler(
            sonuc.data.filter(
              (brifing) =>
                brifing.ilgilikurum === "Tüm Kurumlar" ||
                brifing.ilgilikurum === currentUser.kurumadi
            )
          );
        }
      } else {
        console.error("Veri çekme hatası:", sonuc);
      }
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (etiket) => {
    history.push(`brifingdetay/${etiket}`);
  };

  useEffect(() => {
    getListe();
  }, []);

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row className="g-2">
        <Col sm="12" xl="12" className="mb-5">
          <Card className="mb-5">
            <Card.Body>
              <Row>
                <Col md="9">
                  <h2>Brifing Listesi</h2>
                </Col>
                <Col md="3">
                  <Button
                    variant="primary"
                    className="float-end"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    Yeni Brifing Ekle
                  </Button>
                </Col>
              </Row>
              <hr />

              {loading ? (
                <Loading />
              ) : (
                <Row className="row-cols-1 row-cols-md-3 row-cols-xl-3 g-2">
                  {brifingler.map((brifing) => (
                    <Col key={brifing._id}>
                      <Button
                        variant="link"
                        className="p-0 w-50 h-100 text-start"
                        onClick={() => handleClick(brifing.etiket)}
                      >
                        <div className="card hover-img-scale-up position-relative">
                          <img
                            className="card-img sh-25 scale cursor-pointer"
                            src="/img/pdfresmi.png"
                            alt="brifing"
                          />
                          <div className="card-img-overlay d-flex align-items-end p-0">
                            <div
                              className="w-100 text-center text-white p-2"
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                            >
                              <h5 className="card-title m-0">
                                {brifing.brifingadi}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Button>
                    </Col>
                  ))}
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ListeYukleModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleUpdate={getListe}
      />
    </>
  );
};

export default BrifingListesi;
