import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Servis from "../../services/servis";
import NumberFormat from "react-number-format";
import EkleHarita from "./EkleContainer/EkleHarita";
import EkleResimler from "./EkleContainer/EkleResimler";
import { ilceler } from "../../config";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const YatirimEkle = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const [btnLoading, setBtnLoading] = useState(false);
  const history = useHistory();

  const yillar = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];

  const [seciliYil, setSeciliYil] = useState();
  const durumlar = [
    { value: "Proje Aşamasında", label: "Proje Aşamasında" },
    { value: "İhale Aşamasında", label: "İhale Aşamasında" },
    { value: "Yatırım Tamamlandı", label: "Yatırım Tamamlandı" },
    { value: "Yapım Devam Ediyor", label: "Yapım Devam Ediyor" },
    { value: "İptal Edildi", label: "İptal Edildi" },
  ];
  const [seciliDurum, setSeciliDurum] = useState();

  const [seciliIlce, setSeciliIlce] = useState();

  const validationSchema = Yup.object().shape({
    yil: Yup.string().required("Yatırım Yılını seçiniz"),
    yatirimadi: Yup.string().required("Yatırım Adını giriniz"),
    yatirimtutari: Yup.string().required("Yatırım Tutarını giriniz"),
    durum: Yup.string().required("Yatırım Durumunu seçiniz"),
    ilce: Yup.string().required("İlçe seçiniz"),
    koy: Yup.string().required("Köy/ Mahalle seçiniz"),
    yatirimaciklama: Yup.string().required("Yatırım Açıklamasını giriniz"),
  });
  const initialValues = {
    yil: "",
    yatirimadi: "",
    yatirimtutari: "",
    durum: "",
    ilce: "",
    koy: "",
    yatirimaciklama: "",
    sorun: "",
    lat: 0,
    lng: 0,
  };

  const yilOnChange = (e) => {
    setSeciliYil(e);
    formik.setFieldValue("yil", e.value);
  };

  const durumOnChange = (e) => {
    setSeciliDurum(e);
    formik.setFieldValue("durum", e.value);
  };

  const onIlceChange = (e) => {
    setSeciliIlce(e);
    formik.setFieldValue("ilce", e.value);
  };

  const [koordinat, setKoordinat] = useState({ lat: 0, lng: 0 });

  const handleMarkerPositionChange = (position) => {
    setKoordinat(position);
    formik.setFieldValue("lat", position.lat);
    formik.setFieldValue("lng", position.lng);
  };
  const [eklenenResimler, setEklenenResimler] = useState([]);

  const resimEklendi = (resimler) => {
    setEklenenResimler(resimler);
  };
  const onSubmit = async (values) => {
    if (eklenenResimler.length === 0) {
      toast("Lütfen en az bir resim ekleyiniz.", { className: "danger" });
    } else if (koordinat.lat === 0 || koordinat.lng === 0) {
      toast("Lütfen haritadan konum seçiniz.", { className: "danger" });
    } else {
      setBtnLoading(true);
      try {
        const resimYukleResponse = await Servis.resimYukleYeni(eklenenResimler);
        if (resimYukleResponse.data.status) {
          const yuklenenResimler = resimYukleResponse.data.filePaths.join(";");
          const degerler = {
            yatirimyili: seciliYil.value,
            yatirimadi: values.yatirimadi,
            yatirimaciklama: values.yatirimaciklama,
            ilce: values.ilce,
            koy: values.koy,
            durumu: seciliDurum.value,
            lat: values.lat,
            lng: values.lng,
            yukleyen: currentUser.adsoyad,
            islemyapantel: currentUser.telno,
            kurumu: currentUser.kurumadi,
            kurumid: currentUser.kurumid,
            yatirimtutari: parseFloat(values.yatirimtutari.replace(/\D/g, "")),
            resimler: yuklenenResimler,
            sorun: values.sorun,
          };
          const postYapResponse = await Servis.postYap(
            "yatirimlar/yatirimekle",
            degerler
          );
          if (postYapResponse.data.status) {
            toast("Yatırım başarıyla eklendi.", { className: "success" });
          } else {
            toast("Yatırım eklenirken bir hata oluştu.", {
              className: "danger",
            });
          }
        } else {
          toast("Resimler yüklenirken bir hata oluştu.", {
            className: "danger",
          });
        }
      } catch (error) {
        console.error("Hata:", error);
        toast("Beklenmeyen bir hata oluştu.", { className: "danger" });
      } finally {
        setBtnLoading(false);
        history.push("/yatirimlar/yatirimlistesi");
      }
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const position = [koordinat.lat, koordinat.lng];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
            <h3>Yatırım Ekle</h3>
            <hr className="mt-0 mb-4" />

            <Row>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Yatırım Yılı</Form.Label>
                  <Select
                    options={yillar}
                    value={seciliYil}
                    onChange={yilOnChange}
                    classNamePrefix="react-select"
                    placeholder="Lütfen Seçiniz"
                    required
                  />
                  {errors.yil && touched.yil && (
                    <div className="d-block invalid-tooltip">{errors.yil}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Yatırım Adı</Form.Label>
                  <Form.Control
                    type="text"
                    name="yatirimadi"
                    value={values.yatirimadi}
                    onChange={handleChange}
                  />
                  {errors.yatirimadi && touched.yatirimadi ? (
                    <div className="d-block invalid-tooltip">
                      {errors.yatirimadi}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Yatırım Tutarı</Form.Label>
                  <NumberFormat
                    type="text"
                    name="yatirimtutari"
                    value={values.yatirimtutari}
                    onChange={handleChange}
                    thousandSeparator={true}
                    prefix={"₺"}
                    className="form-control"
                  />
                  {errors.yatirimtutari && touched.yatirimtutari ? (
                    <div className="d-block invalid-tooltip">
                      {errors.yatirimtutari}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Yatırım Durumu</Form.Label>
                  <Select
                    options={durumlar}
                    value={seciliDurum}
                    onChange={durumOnChange}
                    classNamePrefix="react-select"
                    placeholder="Lütfen Seçiniz"
                    required
                  />
                  {errors.durum && touched.durum && (
                    <div className="d-block invalid-tooltip">
                      {errors.durum}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>İlçe</Form.Label>
                  <Select
                    options={ilceler}
                    value={seciliIlce}
                    onChange={onIlceChange}
                    classNamePrefix="react-select"
                    placeholder="Lütfen Seçiniz"
                    required
                  />
                  {errors.ilce && touched.ilce && (
                    <div className="d-block invalid-tooltip">{errors.ilce}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Köy/ Mahalle</Form.Label>
                  <Form.Control
                    type="text"
                    name="koy"
                    value={values.koy}
                    onChange={handleChange}
                  />
                  {errors.koy && touched.koy ? (
                    <div className="d-block invalid-tooltip">{errors.koy}</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Yatırım Açıklaması</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="yatirimaciklama"
                    value={values.yatirimaciklama}
                    onChange={handleChange}
                  />
                  {errors.yatirimaciklama && touched.yatirimaciklama ? (
                    <div className="d-block invalid-tooltip">
                      {errors.yatirimaciklama}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Sorun/Öneri</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="sorun"
                    value={values.sorun}
                    onChange={handleChange}
                  />
                  {errors.sorun && touched.sorun ? (
                    <div className="d-block invalid-tooltip">
                      {errors.sorun}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <Card
          body
          className="mb-5 sh-50"
          style={{ display: "flex", flexDirection: "column", height: "400px" }}
        >
          <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        </Card> */}
        <EkleHarita
          onMarkerPositionChange={handleMarkerPositionChange}
          koordinat={koordinat}
        />
        <EkleResimler resimEklendi={resimEklendi} />
        <Card className="mt-5">
          <Card.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>Yükleyen</Form.Label>
                  <Form.Control
                    type="text"
                    name="yukleyen"
                    value={currentUser.adsoyad}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group position-relative tooltip-end-top">
                  <Form.Label>İşlem Yapan Telefon</Form.Label>
                  <Form.Control
                    type="text"
                    name="islemyapantel"
                    value={currentUser.telno}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4 mt-2">
                <div className="d-flex justify-content-end">
                  <Button type="submit" size="xl" className="mt-3">
                    {btnLoading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      ></Spinner>
                    ) : (
                      "Yatırım Ekle"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default YatirimEkle;
