import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Loading from "components/loading/Loading";
import HtmlHead from "components/html-head/HtmlHead";
import Servis from "../../services/servis";
import { brifingyolu } from "../../config";
import { useSelector } from "react-redux";

const BrifingDetay = () => {
  const title = "Brifing Listesi";
  const description = "";
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { currentUser } = useSelector((state) => state.auth);

  const [brifing, setBrifing] = useState({});

  const brifingGetir = async () => {
    var resp = await Servis.getYap("brifinggetir/" + id);
    if (resp && resp.status) {
      setBrifing(resp.data);
    } else {
      console.error("Veri çekme hatası:", resp);
    }
    setLoading(false);
  };

  useEffect(
    () => {
      brifingGetir();
    },
    // eslint-disable-next-line
    []
  );

  const brifingSil = async () => {
    if (
      brifing.ilgilikurum !== currentUser.kurumadi &&
      currentUser.yetki !== "Sistem Yöneticisi"
    ) {
      Swal.fire("Hata!", "Bu brifingi silme yetkiniz yok.", "error");
      return;
    }

    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlem geri alınamaz! Brifinginiz silinecektir.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "Vazgeç",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await Servis.postYap("brifingsil", { id });
          Swal.fire("Silindi!", "Brifing silindi.", "success");
          window.location.href = "/brifingler";
        } catch (error) {
          Swal.fire("Hata!", "Brifing silinirken bir hata oluştu.", "error");
          console.error("Brifing silme hatası:", error);
        }
      }
    });
  };

  return (
    <>
      <>{loading && <Loading />}</>
      <HtmlHead title={title} description={description} />
      <Row className="g-2">
        <Col sm="12" xl="12" className="mb-5">
          <Card className="mb-5">
            <Card.Body>
              <Row>
                <Col md="11">
                  <h2>{brifing.brifingadi}</h2>
                </Col>
                <Col md="1">
                  <Button
                    variant="danger"
                    className="d-flex justify-content-end"
                    onClick={brifingSil}
                  >
                    Sil
                  </Button>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col md="12">
                  <iframe
                    src={brifingyolu + brifing.resimyolu}
                    width="100%"
                    height="800px"
                    title="Brifing PDF"
                  ></iframe>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BrifingDetay;
