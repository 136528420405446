import { DEFAULT_PATHS } from "config.js";
import AnaEkran from "views/Anasayfa/AnaEkran";

import VeriBaslikEkle from "views/Veriler/VeriBaslikEkle";
import KurumVerileri from "views/Veriler/KurumVerileri";

import KullaniciEkle from "views/Users/KullaniciEkle";
import KullaniciListesi from "views/Users/UserList";
import KullaniciDetay from "views/Users/KullaniciDetay";

import KurumListesi from "views/Kurumlar/KurumListesi";

import KurumFaaliyetEkle from "views/KurumFaaliyet/KurumFaaliyetEkle";
import EklenilenRaporlar from "views/KurumFaaliyet/EklenilenRaporlar";

import YatirimEkle from "views/Yatirimlar/YatirimEkle";
import YatirimHaritasi from "views/Yatirimlar/YatirimHaritasi";
import YatirimDetay from "views/Yatirimlar/YatirimDetay";
import YatirimListesi from "views/Yatirimlar/YatirimListesi";
import IlceYatirimHaritasi from "views/IlceYatirim/IlceYatirimHaritasi";

import IlceRaporEkle from "views/IlceRaporlar/IlceRaporEkle";
import YuklenenRaporlar from "views/IlceRaporlar/YuklenenRaporlar";

// YÖNETİM EKRANLARI
import VerileriListele from "views/Yonetim/VerileriListele";
import KurumFaaliyetListeleYonetim from "views/Yonetim/KurumFaaliyetListele";
import KurumFaaliyetDetay from "views/Yonetim/KurumFaaliyetDetay";
import IlceRaporListesi from "views/Yonetim/IlceRaporListesi";
import IlceRaporDetay from "views/Yonetim/IlceRaporDetay";
import YoneticiYatirimListesi from "views/Yonetim/YatirimListesi";
import YonetimYatirimHaritasi from "views/Yonetim/YatirimHaritasi";
import SabitVerilerYonetim from "views/Yonetim/SabitVeriler";

// SABİT VERİLER
import KurumSabitiVeriler from "views/SabitVeriler/KurumSabitVeriler";

import SabitVeriBaslik from "views/SabitVeriler/KurumSabitBaslik";

// PROFİLE
import Profile from "views/Profile/Profile";

// BRİFİNGLER
import BrifingListesi from "views/Brifingler/BrifingListesi";
import BrifingDetay from "views/Brifingler/BrifingDetay";

// LOGOUT
import Logout from "views/Logout";

const appRoot = DEFAULT_PATHS.APP.endsWith("/")
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/login`,
    },
    {
      path: `${appRoot}/anasayfa`,
      label: "Anasayfa",
      icon: "home",
      component: AnaEkran,
    },
    {
      path: `${appRoot}/brifingler`,
      label: "Brifingler",
      icon: "file-text",
      roles: ["Sistem Yöneticisi", "Kurum Kullanıcısı", "İlçe Kullanıcısı"],
      component: BrifingListesi,
    },
    {
      path: `${appRoot}/brifingdetay/:id`,
      component: BrifingDetay,
      hideInMenu: true,
    },
    {
      path: `${appRoot}/veriler`,
      label: "Aylık Veriler",
      icon: "database",
      roles: ["Sistem Yöneticisi", "Kurum Kullanıcısı"],
      subs: [
        {
          path: `${appRoot}/verigiris`,
          label: "Aylık Veriler",
          component: KurumVerileri,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/baslikekle`,
          label: "Aylık Veri Başlığı",
          component: VeriBaslikEkle,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/listele-yonetim`,
          label: "Verileri Listele",
          component: VerileriListele,
          roles: ["Sistem Yöneticisi"],
        },
      ],
    },
    {
      path: `${appRoot}/sabitveriler`,
      label: "Sabit Veriler",
      icon: "check-circle",
      roles: ["Sistem Yöneticisi", "Kurum Kullanıcısı"],
      subs: [
        {
          path: `${appRoot}/kurumsabitveriler`,
          label: "Kurum Sabit Veriler",
          component: KurumSabitiVeriler,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/sabitveribaslik`,
          label: "Sabit Veri Başlık",
          component: SabitVeriBaslik,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/sabitverileryonetim`,
          label: "Sabit Veriler",
          component: SabitVerilerYonetim,
          roles: ["Sistem Yöneticisi"],
        },
      ],
    },

    {
      path: `${appRoot}/kurumfaaliyet`,
      label: "Kurum Faaliyet",
      icon: "activity",
      roles: ["Kurum Kullanıcısı", "Sistem Yöneticisi"],
      subs: [
        {
          path: `${appRoot}/kurumfaaliyetekle`,
          label: "Kurum Faaliyet Ekle",
          component: KurumFaaliyetEkle,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/eklenilenraporlar`,
          label: "Eklenilen Raporlar",
          component: EklenilenRaporlar,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/kurumfaaliyetlistele`,
          label: "Faaliyet Listele",
          component: KurumFaaliyetListeleYonetim,
          roles: ["Sistem Yöneticisi"],
        },
        {
          path: `${appRoot}/kurumfaaliyetdetay/:id`,
          component: KurumFaaliyetDetay,
          hideInMenu: true,
        },
      ],
    },
    {
      path: `${appRoot}/ilcerapor`,
      label: "İlçe Raporlar",
      icon: "activity",
      roles: ["Sistem Yöneticisi", "İlçe Kullanıcısı", "Kaymakam"],
      subs: [
        {
          path: `${appRoot}/ilceraporekle`,
          label: "İlçe Rapor Ekle",
          component: IlceRaporEkle,
          roles: ["İlçe Kullanıcısı", "Kaymakam"],
        },
        {
          path: `${appRoot}/yuklenenraporlar`,
          label: "Yüklenen Raporlar",
          component: YuklenenRaporlar,
          roles: ["İlçe Kullanıcısı", "Kaymakam"],
        },
        {
          path: `${appRoot}/ilceraporlistele`,
          label: "Rapor Listele",
          component: IlceRaporListesi,
          roles: ["Sistem Yöneticisi"],
        },
        {
          path: `${appRoot}/ilcerapordetay/:id`,
          component: IlceRaporDetay,
          hideInMenu: true,
        },
      ],
    },

    {
      path: `${appRoot}/yatirimlar`,
      label: "Yatırımlar",
      icon: "note",
      roles: [
        "Kurum Kullanıcısı",
        "İlçe Kullanıcısı",
        "Sistem Yöneticisi",
        "Kaymakam",
      ],
      subs: [
        {
          path: `${appRoot}/yatirimekle`,
          label: "Yatırım Ekle",
          component: YatirimEkle,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/yatirimharitasi`,
          label: "Yatırım Haritası",
          component: YatirimHaritasi,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/yatirimlistesi`,
          label: "Yatırım Listesi",
          component: YatirimListesi,
          roles: ["Kurum Kullanıcısı"],
        },
        {
          path: `${appRoot}/ilcedekiyatirimlar`,
          label: "İlçe Yatırımlar",
          component: IlceYatirimHaritasi,
          roles: ["İlçe Kullanıcısı"],
        },
        {
          path: `${appRoot}/yatirimdetay/:id`,
          component: YatirimDetay,
          hideInMenu: true,
          roles: [
            "Kurum Kullanıcısı",
            "İlçe Kullanıcısı",
            "Sistem Yöneticisi",
            "Kaymakam",
          ],
        },
        {
          path: `${appRoot}/yonetimyaririmlistesi`,
          label: "Yatırım Listesi",
          component: YoneticiYatirimListesi,
          roles: ["Sistem Yöneticisi"],
        },
        {
          path: `${appRoot}/yonetimyatirimharitasi`,
          label: "Yatırım Haritası",
          component: YonetimYatirimHaritasi,
          roles: ["Sistem Yöneticisi"],
        },
      ],
    },
    {
      path: `${appRoot}/kurumlar`,
      label: "Kurumlar",
      icon: "server",
      roles: ["Sistem Yöneticisi"],
      subs: [
        {
          path: `${appRoot}/kurumlistesi`,
          label: "Kurum Listesi",
          component: KurumListesi,
        },
      ],
    },
    {
      path: `${appRoot}/kullanicilar`,
      label: "Kullanıcılar",
      icon: "user",
      roles: ["Sistem Yöneticisi"],
      subs: [
        {
          path: `${appRoot}/kullaniciekle`,
          label: "Kullanıcı Ekle",
          component: KullaniciEkle,
        },
        {
          path: `${appRoot}/liste`,
          label: "Kullanıcı Listesi",
          component: KullaniciListesi,
        },
        {
          path: `${appRoot}/kullanicidetay/:id`,
          component: KullaniciDetay,
          hideInMenu: true,
        },
      ],
    },
    {
      path: `${appRoot}/profile`,
      label: "Bilgilerim",
      component: Profile,
      icon: "user",
    },
    {
      path: `${appRoot}/logout`,
      label: "Çıkış",
      icon: "logout",
      component: Logout,
    },
  ],
  sidebarItems: [],
};

export default routesAndMenuItems;
