import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Select from "react-select";

import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Servis from "../../services/servis";

const ListeYukleModal = ({ show, handleClose, handleUpdate }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [kurumlar, setKurumlar] = useState([]);
  const { currentUser } = useSelector((state) => state.auth);
  const formik = useFormik({
    initialValues: {
      brifingadi: "",
      dosya: null,
      ilgiliKurum: null,
    },
    validationSchema: Yup.object({
      brifingadi: Yup.string().required("Brifing adı zorunlu"),
      dosya: Yup.mixed().required("Dosya zorunlu"),
      ilgiliKurum: Yup.object()
        .shape({
          value: Yup.string().required("İlgili kurum zorunlu"),
          label: Yup.string().required("İlgili kurum zorunlu"),
        })
        .nullable()
        .required("İlgili kurum zorunlu"),
    }),
    onSubmit: (values) => {
      brifingListeEkle();
    },
  });

  const kurumListesiGetir = async () => {
    try {
      const sonuc = await Servis.getYap("kurumlar/kurumgetir");
      if (sonuc && sonuc.status) {
        let kurumlarList = [
          { value: "tumkurumlar", label: "Tüm Kurumlar" },
          ...sonuc.data.map((kurum) => ({
            value: kurum.kurumadi,
            label: kurum.kurumadi,
          })),
        ];
        if (currentUser.yetki !== "Sistem Yöneticisi") {
          kurumlarList = kurumlarList.filter(
            (kurum) => kurum.value === currentUser.kurumadi
          );
        }
        setKurumlar(kurumlarList);
      } else {
        toast.error("Kurumlar getirilemedi");
      }
    } catch (error) {
      toast.error("Kurumlar getirilemedi");
    }
  };

  useEffect(
    () => {
      kurumListesiGetir();
    },
    // eslint-disable-next-line
    []
  );

  const brifingListeEkle = async () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("brifingadi", formik.values.brifingadi);
    formData.append("file", formik.values.dosya);
    formData.append("ilgilikurum", formik.values.ilgiliKurum.label);
    try {
      const sonuc = await Servis.postYap("brifinglisteresimyukle", formData);
      if (sonuc && sonuc.status) {
        toast.success(sonuc.message);
        handleUpdate();
        handleClose();
      } else {
        toast.error(sonuc.message);
      }
    } catch (error) {
      toast.error("Bir hata oluştu");
    } finally {
      setBtnLoading(false);
    }
  };

  const { handleSubmit, handleChange, values, touched, errors } = formik;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Yeni Brifing Listesi Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridBrifingAdi">
              <Form.Label>Brifing Adı</Form.Label>
              <Form.Control
                type="text"
                name="brifingadi"
                value={values.brifingadi}
                onChange={handleChange}
                isInvalid={touched.brifingadi && errors.brifingadi}
              />
              <Form.Control.Feedback type="invalid">
                {errors.brifingadi}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridKurum">
              <Form.Label>İlgili Kurum</Form.Label>
              <Select
                options={kurumlar}
                onChange={(selectedOption) => {
                  formik.setFieldValue("ilgiliKurum", selectedOption);
                }}
                value={formik.values.ilgiliKurum}
                isInvalid={touched.ilgiliKurum && errors.ilgiliKurum}
              />
              {touched.ilgiliKurum && errors.ilgiliKurum ? (
                <div className="invalid-feedback d-block">
                  {errors.ilgiliKurum}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridDosya">
              <Form.Label>Brifing</Form.Label>
              <Form.Control
                type="file"
                name="dosya"
                onChange={(event) => {
                  formik.setFieldValue("dosya", event.currentTarget.files[0]);
                }}
                isInvalid={touched.dosya && errors.dosya}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dosya}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button variant="primary" type="submit" disabled={btnLoading}>
            {btnLoading ? "Yükleniyor..." : "Yükle"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ListeYukleModal;
